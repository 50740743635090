import React from "react";
import s from "./Footer.module.scss";
import {
  PhoneOutlined,
  MailOutlined,
  EnvironmentOutlined,
  TeamOutlined,
  SettingOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";

const Footer = () => {
  return (
    <div className={s.footer}>
      <div className={s.content}>
        <img src='/images/3.jpeg' alt='' className={s.img} />
        <div className={s.inner}>
          <div className={s.logo}>
            <img src='/images/logo.png' className={s.logo_img} />
          </div>
          <div className={s.footer_main}>
            <div className={s.footer_item}>
              <div className={s.item_title}>КОНТАКТЫ</div>
              <div className={s.item_block}>
                <div>
                  <MailOutlined className={s.icon} /> &nbsp; office@mc-trade.net
                </div>
              </div>
            </div>
            <div className={s.footer_item}>
              <div className={s.item_title}>ССЫЛКИ</div>
              <div className={s.item_block}>
                <div>
                  <TeamOutlined className={s.icon} /> &nbsp; Advisory committee
                </div>
                <div>
                  <SettingOutlined className={s.icon} /> &nbsp; Services
                </div>
                <div>
                  <ReconciliationOutlined className={s.icon} /> &nbsp; Events
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
