import React from "react";
import s from "./Team.module.scss";
import TeamMember from "../TeamMember/TeamMember";

const members = [
  {
    name: "Prof. IGBAL BABAYEV",
    img: "/images/babayev.jpg",
    country: "Azerbaijan",
    cv: "/files/CV-Igbal Babayev.pdf",
    position: "Head of the Advisory Committee",
    flag: "/images/azerbaijan.png",
  },
  {
    name: "Mr.UMUT SHEDELE",
    img: "/images/umut.jpg",
    country: "Turkey",
    cv: "/files/CV-Umut.pdf",
    position: "Member of the Advisory Group",
    flag: "/images/turkey.png",
  },
  {
    name: "Mr.VAKHTANG LASHKARADZE",
    img: "/images/lashkaradze.jpg",
    country: "Georgia",
    cv: "/files/CV-Vakhtang Lashkaradze.pdf",
    position: "Member of the Advisory Group",
    flag: "/images/georgia.png",
  },
  {
    name: "",
    img: "/images/no-photo.png",
    country: "Kazakhstan",
    cv: "",
    position: "",
    flag: "/images/kazakhstan.png",
  },
  {
    name: "Prof. ABDUSOBIR SAIDOV",
    img: "/images/saidov.jpg",
    country: "Uzbekistan",
    cv: "/files/CV-Abdusobir_Saidov.pdf",
    position: "Member of the Advisory Group",
    flag: "/images/uzbekistan.png",
  },
  {
    name: "MR. TOOTAEV NURLAN",
    img: "/images/Tootaev.jfif",
    country: "Kyrgyzstan",
    cv: "/files/CV_Tootaev N.pdf",
    position: "Member of the Advisory Group",
    flag: "/images/kyrgyzstan.png",
  },
  {
    name: "",
    img: "/images/no-photo.png",
    country: "Iran",
    cv: "",
    position: "",
    flag: "/images/iran.png",
  },
  {
    name: "",
    img: "/images/no-photo.png",
    country: "Russia",
    cv: "",
    position: "",
    flag: "/images/russia.png",
  },
  {
    name: "Prof. PAVLO PASHKO",
    img: "/images/pashko.jpg",
    country: "Ukraine",
    cv: "/files/CV - Pavlo Pashko.pdf",
    position: "Member of the Advisory Group",
    flag: "/images/ukraine.png",
  },
  {
    name: "Mr. VADIM MUNTEAN",
    img: "/images/muntean.jfif",
    country: "Moldova",
    cv: "/files/CV-Vadim Muntean.pdf",
    position: "Member of the Advisory Group",
    flag: "/images/moldova.png",
  },
  {
    name: "Mr. Vytautas Vitkauskas",
    img: "/images/Vytautas.jpg",
    country: "Lithuania",
    cv: "/files/CV-Vytautas_Vitkauska.pdf",
    position: "Member of the Advisory Group",
    flag: "/images/lithuania.png",
  },
  {
    name: "Mr.GIVI NADIRADZE",
    img: "/images/nadiradze.jpg",
    country: "Georgia",
    cv: "/files/CV-Givi Nadiradze.pdf",
    position: "Consultant- International Trade facilitation",
    flag: "/images/georgia.png",
  },
  {
    name: "Mr.FIKRET ALESGERLİ",
    img: "/images/alesgerli.jfif",
    country: "",
    cv: "/files/CV - Fikret Elesgerli.pdf",
    position: "Consultant, Black Sea Graine Initiative, JCC",
    flag: "/images/azerbaijan.png",
  },
  {
    name: "Mr. ZAUR BAGIROV",
    img: "/images/zaur.jpg",
    country: "",
    cv: "/files/CV - Zaur Bagirov.pdf",
    position: "Consultant - Absheron Logistics Center (Caspian See)",
    flag: "/images/azerbaijan.png",
  },
];

const Team = () => {
  return (
    <div className={s.team}>
      <h3 className={s.h3}>CURRENT MEMBERS OF THE ADVISORY COMMITTEE</h3>
      <div className={s.members}>
        {members.map((member) => (
          <TeamMember member={member} />
        ))}
      </div>
    </div>
  );
};

export default Team;
