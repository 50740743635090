import React from "react";
import s from "./About.module.scss";
import ActivityItem from "../ActivityItem/ActivityItem";
import { DockerOutlined, EditFilled, MergeFilled } from "@ant-design/icons";
import ActivityDirections from "../ActivityDirections/ActivityDirections";

const data = [
  {
    id: 1,
    img: "/images/2.jpeg",
    desc: "Support Middle Corridor trade on a B2B basis",
    icon: <DockerOutlined />,
  },
  {
    id: 2,
    img: "/images/3.jpeg",
    desc: "Provide relevant services related to business development and sustainability",
    icon: <MergeFilled />,
  },
  {
    id: 3,
    img: "/images/legal.jpeg",
    desc: "Provide legal advice and support on procedures to import / export / transit companies",
    icon: <EditFilled />,
  },
];
const About = () => {
  return (
    <div className={s.about}>
      <h2 className={s.title}>ABOUT MCTAC</h2>
      <div className={s.about_content}>
        <div className={s.intro}>
          <strong>MCTAC (Middle corridor trade advisory committee) </strong> is
          a company that is interested in the implementation of electronic trade
          systems and trade facilitation and it is part of the company's
          strategic plan to conduct research, survey, provide analysis on trade
          facilitation, simplification of customs procedures and cross-border
          management in the Middle Corridor region, and to implement joint
          projects based on regional cooperation.
        </div>
        <div className={s.subtitle}>THE MAIN ACTIVITIES OF MCTAC</div>
      </div>
      <div className={s.activities}>
        {data.map((it) => (
          <ActivityItem data={it} key={it.id} />
        ))}
      </div>
      <div className={s.text_wrapper}>
        <div className={s.text_section}>
          MCTAC's advisory group includes experts from state institutions, the
          private sector, public associations, scientific and educational
          institutions, regional and international organizations.
        </div>
        <div className={s.text_section}>
          MCTAC works closely with relevant regional and global organizations
        </div>
      </div>
      <ActivityDirections />
    </div>
  );
};

export default About;
