import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import Committee from "./pages/Committee/Committee";
import Tarrifs from "./pages/Tariffs/Tarrifs";
import Organigram from "./pages/Organigram/Organigram";
import Career from "./pages/Career/Career";
import Events from "./pages/Events/Events";
import Agenda from "./pages/Agenda/Agenda";
import Program from "./pages/Program/Program";
import Participants from "./pages/Participants/Participants";
import EventsList from "./pages/EventsList/EventsList";
import News from "./pages/News/News";
import NewsDetails from "./pages/NewsDetails/NewsDetails";

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/committee' element={<Committee />} />
          <Route path='/tarrifs' element={<Tarrifs />} />
          <Route path='/organigram' element={<Organigram />} />
          <Route path='/career' element={<Career />} />
          <Route path='/events' element={<EventsList />} />
          <Route path='/events/:id' element={<Events />} />
          <Route path='/agenda/:id' element={<Agenda />} />
          <Route path='/program/:id' element={<Program />} />
          <Route path='/news' element={<News />} />
          <Route path='/news/:id' element={<NewsDetails />} />
          <Route path='/participants/:id' element={<Participants />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
