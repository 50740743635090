import React, {useEffect} from "react";
import Header from "../../components/Header/Header";
import NewsItem from "../../components/NewsItem/NewsItem";
import Footer from "../../components/Footer/Footer";
import s from "./News.module.scss";
import useWindowDimensions from "../../components/WindowDimentions/WindowDimentions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import {useDispatch, useSelector} from "react-redux";
import {getNews} from "../../redux/ApiSlice";

const News = () => {
    const { width } = useWindowDimensions();
    const news = useSelector((state) => state.content.news);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getNews());
    }, [news]);
    return (
        <div>
            {width > 1000 ? (
                <div className={s.header_wrapper}>
                    <Header />
                </div>
            ) : (
                <MobileHeader />
            )}
            <h2 className={s.h2}>NEWS</h2>
            <div className={s.news}>
                {Array.isArray(news) && news.map((news) => (
                    <NewsItem news={news} />
                ))}
            </div>
            <Footer />
        </div>
    );
};

export default News;
