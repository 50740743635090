import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import s from "./NewsDetails.module.scss";
import { useSelector } from "react-redux";
import useWindowDimensions from "../../components/WindowDimentions/WindowDimentions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";

const NewsDetails = () => {
  const news = useSelector((state) => state.content.newsDetails);
  const { width } = useWindowDimensions();

  return (
    <div>
      {width > 1200 ? (
        <div className={s.header_wrapper}>
          <Header />
        </div>
      ) : (
        <MobileHeader />
      )}
      <div className={s.content}>
        <div className={s.news_img}>
          <img src={news?.image} className={s.img} />
        </div>
        <h2 className={s.title}>{news?.title?.toUpperCase()}</h2>
        <div className={s.news_header}>
          <div className={s.category}>{news?.category?.name}</div>
          <div className={s.date}>{news?.created_at}</div>
        </div>
        <div
          className={s.news_description}
          dangerouslySetInnerHTML={{ __html: news?.body }}
        ></div>
      </div>
      <Footer />
    </div>
  );
};

export default NewsDetails;
