import React from "react";
import s from "./Organogram.module.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import useWindowDimensions from "../../components/WindowDimentions/WindowDimentions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";

const Organigram = () => {
  const { width } = useWindowDimensions();
  return (
    <div>
      <div className={s.header}>
        {width > 1000 ? (
          <div className={s.header_wrapper}>
            <Header />
          </div>
        ) : (
          <MobileHeader />
        )}
      </div>
      <h2 className={s.h2}>ORGANIGRAM</h2>
      <div className={s.img_wrapper}>
        <img src='/images/organigram.png' className={s.img} />
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Organigram;
