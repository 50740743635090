import React from "react";
import s from "./NewsItem.module.scss";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getNewsDetails } from "../../redux/ApiSlice";

const NewsItem = ({ news }) => {
  const dispatch = useDispatch();

  return (
    <div className={s.news} onClick={() => dispatch(getNewsDetails(news.id))}>
      <Link to={`/news/${news.id}`}>
        <div className={s.img_wrapper}>
          {news.image ? (
            <img src={news?.image} className={s.img} />
          ) : (
            <img src={"/images/image.png"} className={s.noimg} />
          )}
        </div>
        <div className={s.divider}></div>
        <div className={s.text}>
          <div className={s.subtitle}>{news?.additional_title}</div>
          <div className={s.title}>{news?.title}</div>
          <div className={s.date}>{news?.created_at}</div>
          <div className={s.location}>{news?.location}</div>
        </div>
      </Link>
    </div>
  );
};

export default NewsItem;
