import React from "react";
import s from "./Career.module.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import useWindowDimensions from "../../components/WindowDimentions/WindowDimentions";

const Career = () => {
  const { width } = useWindowDimensions();
  return (
    <div className={s.career_wrapper}>
      <div className={s.header}>
        {width > 1000 ? (
          <div className={s.header_wrapper}>
            <Header />
          </div>
        ) : (
          <MobileHeader />
        )}
      </div>
      <div className={s.career}>
        <h2 className={s.h2}>CAREER OPPORTUNITIES</h2>
      </div>
      <div className={s.text}>
        <div>
          The MCTAC attaches particular importance to the development of human
          capital, as well as the implementation of effective and transparent
          human resources policy.
        </div>
        <div>
          MCTAC strengthens its competitiveness by having highly qualified and
          professional human resources based on intellectual knowledge.
        </div>
        <div>
          Currently MCTAC employs staff with extensive experience in customs
          issues.
        </div>
        <div>
          MCTAC attaches great value to promoting professional development and
          education of its staff, thus providing them with opportunities to
          attend trainings, workshops and courses held by different national and
          international organizations.
        </div>
        <div>
          MCTAC recruitment procedure represents a series of step-by-step
          interviews conducted in a transparent, equal, and impartial way. Our
          main criteria are: education, discipline, knowledge and honesty.
        </div>
        <div>
          Our team is a friendly and sincere collective of professional
          specialists, so you will be able to improve your qualifications, gain
          great experience and constantly develop with us.
        </div>
        <div>VOLUNTEERS are our future!!!</div>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Career;
