import React from "react";
import s from "./EventItem.module.scss";
import { Link } from "react-router-dom";
import { EnvironmentOutlined, CalendarOutlined } from "@ant-design/icons";
import { getEventDetails, getEventPhotos } from "../../redux/ApiSlice";
import { useDispatch } from "react-redux";

const EventItem = ({ event }) => {
  const dispatch = useDispatch();

  return (
    <Link
      to={`/events/${event.id}`}
      onClick={() => {
        dispatch(getEventDetails(event.id));
        dispatch(getEventPhotos(event.id));
      }}
    >
      <div className={s.event}>
        <div className={s.img_wrapper}>
          {event.image ? (
            <img src={event.image} className={s.img} />
          ) : (
            <img src={event?.image} className={s.noimg} />
          )}
        </div>
        <div className={s.text}>
          <div className={s.subtitle}>{event.additional_title}</div>
          <div className={s.title}>{event?.title}</div>
          <div className={s.header}>
            <div className={s.date}>
              <CalendarOutlined className={s.icon} /> &nbsp; {event.date}
            </div>
            <div className={s.location}>
              {" "}
              <EnvironmentOutlined className={s.icon} /> &nbsp; {event.location}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default EventItem;
