import React from "react";
import Header from "../../components/Header/Header";
import s from "./Committee.module.scss";
import Footer from "../../components/Footer/Footer";
import Team from "../../components/Team/Team";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import useWindowDimensions from "../../components/WindowDimentions/WindowDimentions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";

const Committee = () => {
  const { width } = useWindowDimensions();
  return (
    <div>
      <div className={s.committee_header}>
        {width > 1000 ? (
          <div className={s.header_wrapper}>
            <Header />
          </div>
        ) : (
          <MobileHeader />
        )}
      </div>
      <h2 className={s.h2}>Advisory Committee</h2>
      <div className={s.text}>
        <div className={s.text_section}>
          <div>Dear colleague! </div>
          <div>
            We are inviting you to join our growing family of international
            organization - MIDDLE CORRIDOR TRADE ADVISORY COMMITTEE (MCTAC).
          </div>
          <div>
            The MCTAC uses committees and panels to obtain independent expert
            advice on trade facilitation, digital trade, business development,
            customs clearance, cross-border management, AEO, logistics, customs
            brokerage institution matters.{" "}
          </div>
          <div>
            An Advisory Committee is a gathering of individuals, regularly
            volunteers, who are inward as well as outside to MCTAC on issues so
            as to all the more adequately manage the organization. MCTAC seeks
            to include the views of all experts in these fields on its advisory
            committees. An Advisory Committee comprise of qualified, proficient
            individuals who has many years of experience connected with working
            in the in the above-mentioned fields, including international
            cooperation with widely understood trade facilitation.
          </div>

          <div>
            {" "}
            Members of the Advisory Committee specialize in the following areas:
          </div>
          <div className={s.areas_list}>
            <div className={s.list_item}>
              - Trade facilitation and legal support for business development
            </div>
            <div className={s.list_item}>
              - Logistics, transit corridors and cross-border management
            </div>
            <div className={s.list_item}>- Customs clearance and control</div>
            <div className={s.list_item}>
              - Digital trade, digital transformation, and e-services
            </div>
            <div className={s.list_item}>- Customs Risk Management</div>
            <div className={s.list_item}>
              - Authorized Economic Operator (AEO)
            </div>
          </div>
        </div>
      </div>
      <Team />

      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Committee;
