import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import s from "./Events.module.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  EnvironmentOutlined,
  ClockCircleOutlined,
  FileDoneOutlined,
  SnippetsOutlined,
  ArrowLeftOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { useDispatch, useSelector } from "react-redux";
import {
  getEventAgenda,
  getEventParticipants,
  getEventProgram,
} from "../../redux/ApiSlice";
import useWindowDimensions from "../../components/WindowDimentions/WindowDimentions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1950 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1950, min: 1450 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Events = () => {
  const event = useSelector((state) => state.content.eventDetails);
  const dispatch = useDispatch();
  const photos = useSelector((state) => state.content.photos);
  const { width } = useWindowDimensions();

  return (
    <div>
      {width > 1200 ? (
        <div className={s.header_wrapper}>
          <Header />
        </div>
      ) : (
        <MobileHeader />
      )}
      <Link to='/events' className={s.link}>
        {" "}
        <ArrowLeftOutlined className={s.arrowIcon} /> Back to Events List
      </Link>
      <div className={s.wrapper}>
        <h2 className={s.h2}>{event?.additional_title}</h2>
        <div className={s.events}>
          <div className={s.title}>{event?.title?.toUpperCase()}</div>
          <div className={s.common_info}>
            <div className={s.place}>
              <EnvironmentOutlined /> {event?.location}
            </div>
            <div className={s.date}>
              <ClockCircleOutlined /> {event?.date}
            </div>
          </div>
        </div>
        <div
          className={s.introduction}
          dangerouslySetInnerHTML={{ __html: event?.body }}
        />
        <div className={s.content_wrapper}>
          <div className={s.title}>Important Links</div>
          <div
            className={s.content}
            onClick={() => dispatch(getEventAgenda(event?.id))}
          >
            <Link to={`/agenda/${event?.id}`}>
              <div className={s.content_item}>
                <img src='/images/conference.jpeg' className={s.item_img} />
                <div className={s.item_title}>
                  {" "}
                  <SnippetsOutlined className={s.icon} /> AGENDA
                </div>
              </div>
            </Link>
            <Link
              to={`/program/${event?.id}`}
              onClick={() => dispatch(getEventProgram(event?.id))}
            >
              <div className={s.content_item}>
                <img src='/images/programm.webp' className={s.item_img} />
                <div className={s.item_title}>
                  {" "}
                  <FileDoneOutlined className={s.icon} /> PROGRAM OF THE
                  CONFERENCE
                </div>
              </div>
            </Link>
            <Link
              to={`/participants/${event?.id}`}
              onClick={() => dispatch(getEventParticipants(event.id))}
            >
              <div className={s.content_item}>
                <img src='/images/participants.webp' className={s.item_img} />
                <div className={s.item_title}>
                  <TeamOutlined className={s.icon} /> LIST of PARTICIPANTS
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className={s.gallery}>
          <div className={s.title}>Photo Gallery</div>
        </div>
        <Footer />
        <ScrollToTop />
      </div>
    </div>
  );
};

export default Events;
