import React from "react";
import s from "./Header.module.scss";
import { Link } from "react-router-dom";
import { Dropdown } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getEvents, getNews, getTariff } from "../../redux/ApiSlice";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const redirectToPage = (page) => {
    if (page === "about") {
      navigate("/");
    }
    if (page === "organigram") {
      navigate("/organigram");
    }
    if (page === "career") {
      navigate("/career");
    }
  };

  const items = [
    {
      key: "1",
      label: "Organigram",
      onClick: (e) => redirectToPage("organigram"),
    },
    {
      key: "2",
      label: "Careers",
      onClick: (e) => redirectToPage("career"),
    },
  ];

  return (
    <div className={location.pathname === "/" ? s.header : s.header2}>
      {location.pathname !== "/" ? (
        <img src='/images/header.png' className={s.header_img} />
      ) : null}
      <div className={s.logo}>
        <Link to='/' style={{ textDecoration: "none", color: "inherit" }}>
          <img src={"/images/logo.png"} className={s.logo_img} />
        </Link>
      </div>
      <div className={s.menu_wrapper}>
        <ul className={s.menu}>
          <li className={s.menu_item}>
            <Dropdown
                menu={{
                  items,
                  selectable: true,
                  defaultSelectedKeys: ["1"],
                }}
            >
              <Link to='/' style={{textDecoration: "none", color: "inherit"}}>
                ABOUT US
              </Link>
            </Dropdown>
          </li>
          <li className={s.menu_item}>
            {" "}
            <Link to='/committee' style={{letterSpacing: "1.5px"}}>
              ADVISORY COMMITTEE
            </Link>
          </li>
          <li className={s.menu_item}>
            {" "}
            <Link to='/tarrifs' onClick={() => dispatch(getTariff())}>
              CUSTOMS REPRESENTATION{" "}
            </Link>{" "}
          </li>
          <li className={s.menu_item}>
            {" "}
            <Link to='/events' onClick={() => dispatch(getEvents())}>
              EVENTS
            </Link>
          </li>
          <li className={s.menu_item}>
            {" "}
            <Link to='/news' onClick={() => dispatch(getNews())}>
              NEWS
            </Link>
          </li>
          <li>
            <link rel="stylesheet"
                  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
            <a style={{ fontSize: '30px' }} className="btn" href="https://admin.mc-trade.net/login"><i className="fa fa-sign-in"></i></a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
