import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Slider from "../../components/Slider/Slider";
import About from "../../components/About/About";
import Footer from "../../components/Footer/Footer";
import Map from "../../components/Map/Map";
import { getTariff } from "../../redux/ApiSlice";
import { useDispatch } from "react-redux";

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTariff());
  }, []);

  return (
    <div>
      <Slider />
      <About />
      <Map />
      <Footer />
    </div>
  );
};

export default Home;
