import React, { useEffect } from "react";
import s from "./EventsList.module.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import EventItem from "../../components/EventItem/EventItem";
import useWindowDimensions from "../../components/WindowDimentions/WindowDimentions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { useDispatch, useSelector } from "react-redux";
import { getEvents } from "../../redux/ApiSlice";

const EventsList = () => {
  const { width } = useWindowDimensions();
  const events = useSelector((state) => state.content.events);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEvents());
  }, [events]);
  return (
    <div>
      {width > 1200 ? (
        <div className={s.header_wrapper}>
          <Header />
        </div>
      ) : (
        <MobileHeader />
      )}
      <h2 className={s.h2}>INTERNATIONAL EVENTS</h2>
        <div className={s.events}>
            {Array.isArray(events) && events.map((event) => (
                <EventItem key={event.id} event={event} />
            ))}
      </div>
      <Footer />
    </div>
  );
};

export default EventsList;
