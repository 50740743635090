import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import s from "./Participants.module.scss";
import { Table } from "antd";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../components/WindowDimentions/WindowDimentions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import "../../App.css";

const columns = [
  {
    title: "№",
    dataIndex: "number",
  },
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: `Country`,
    dataIndex: "country",
  },
];

const Participants = () => {
  const participants = useSelector((state) => state.content.participants);
  const width = useWindowDimensions();

  return (
    <div>
      {width > 1200 ? (
        <div className={s.header_wrapper}>
          <Header />
        </div>
      ) : (
        <MobileHeader />
      )}
      <Link to='/events/1' className={s.link}>
        {" "}
        <ArrowLeftOutlined className={s.arrowIcon} /> Back to Event
      </Link>
      <div className={s.content}>
        <h2 className={s.h2}>LIST of PARTICIPANTS</h2>
        <div className={s.table}>
          <Table
            bordered
            pagination={false}
            columns={columns}
            dataSource={participants?.map((it, index) => ({
              number: <div className={s.td}>{index + 1}</div>,
              name: <div className={s.td}>{it?.name}</div>,
              country: <div className={s.td}>{it?.country}</div>,
            }))}
          />
        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Participants;
