import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { publicRequest } from "../utils";

const initialState = {
  news: [],
  events: [],
  newsDetails: {},
  eventDetails: {},
  agenda: [],
  participants: [],
  photos: [],
  program: [],
  tariffs: [],
};

export const getNews = createAsyncThunk("news/getNews", async () => {
  try {
    const { data } = await publicRequest.get("news");
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const getEvents = createAsyncThunk("events/getEvents", async () => {
  try {
    const { data } = await publicRequest.get("events");
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const getNewsDetails = createAsyncThunk(
  "news/getNewsDetails",
  async (id) => {
    try {
      const { data } = await publicRequest.get(`news/${id}`);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getEventDetails = createAsyncThunk(
  "events/getEventDetails",
  async (id) => {
    try {
      const { data } = await publicRequest.get(`events/${id}`);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getEventAgenda = createAsyncThunk(
  "events/getEventAgenda",
  async (id) => {
    try {
      const { data } = await publicRequest.get(`event/${id}/agenda_items`);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getEventParticipants = createAsyncThunk(
  "events/getEventParticipants",
  async (id) => {
    try {
      const { data } = await publicRequest.get(`event/${id}/participants`);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getEventProgram = createAsyncThunk(
  "events/getEventProgram",
  async (id) => {
    try {
      const { data } = await publicRequest.get(
        `event/${id}/conference_program`
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getEventPhotos = createAsyncThunk(
  "events/getEventPhotos",
  async (id) => {
    try {
      const { data } = await publicRequest.get(`event/${id}/photos`);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getTariff = createAsyncThunk("events/getTariff", async () => {
  try {
    const { data } = await publicRequest.get(`tariffs`);
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const apiSlice = createSlice({
  name: "content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNews.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNews.fulfilled, (state, action) => {
        state.news = action.payload;
        state.loading = false;
      })
      .addCase(getNews.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getEvents.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEvents.fulfilled, (state, action) => {
        state.events = action.payload;
        state.loading = false;
      })
      .addCase(getEvents.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getNewsDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNewsDetails.fulfilled, (state, action) => {
        state.newsDetails = action.payload;
        state.loading = false;
      })
      .addCase(getNewsDetails.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getEventDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEventDetails.fulfilled, (state, action) => {
        state.eventDetails = action.payload;
        state.loading = false;
      })
      .addCase(getEventDetails.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getEventAgenda.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEventAgenda.fulfilled, (state, action) => {
        state.agenda = action.payload;
        state.loading = false;
      })
      .addCase(getEventAgenda.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getEventParticipants.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEventParticipants.fulfilled, (state, action) => {
        state.participants = action.payload;
        state.loading = false;
      })
      .addCase(getEventParticipants.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getEventProgram.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEventProgram.fulfilled, (state, action) => {
        state.program = action.payload;
        state.loading = false;
      })
      .addCase(getEventProgram.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getEventPhotos.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEventPhotos.fulfilled, (state, action) => {
        state.photos = action.payload;
        state.loading = false;
      })
      .addCase(getEventPhotos.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getTariff.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTariff.fulfilled, (state, action) => {
        state.tariffs = action.payload;
        state.loading = false;
      })
      .addCase(getTariff.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default apiSlice.reducer;
