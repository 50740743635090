import React from "react";
import s from "./ActivityItem.module.scss";

const ActivityItem = ({ data }) => {
  return (
    <div className={s.activityItem}>
      <div className={s.img_wrapper}>
        <img src={data.img} className={s.img} />
      </div>
      <div className={s.text}>
        <div className={s.description}>
          <div className={s.desc}>
            <div className={s.icon}>
              {data.icon} &nbsp; {data.desc}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityItem;
