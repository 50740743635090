import React from "react";
import s from "./Slider.module.scss";
import Header from "../Header/Header";
import useWindowDimensions from "../WindowDimentions/WindowDimentions";
import MobileHeader from "../MobileHeader/MobileHeader";

const Slider = () => {
  const { width } = useWindowDimensions();

  return (
    <div className={s.slider}>
      {width > 1000 ? (
        <div className={s.header_wrapper}>
          <Header />
        </div>
      ) : (
        <MobileHeader />
      )}

      <div className={s.img_wrapper}>
        <img src='/images/13.jpg' className={s.img} />
      </div>
      <div className={s.title}>
        <div className={s.subtitle}>DIGITAL SILK ROAD</div>
        Middle Corridor Trade Advisory Committee
      </div>
    </div>
  );
};

export default Slider;
