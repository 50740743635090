import React from "react";
import s from "./ActivityDirections.module.scss";

const ActivityDirections = () => {
  return (
    <div className={s.directions}>
      <div className={s.subtitle}>THE COMPANY ACTIVITIES</div>
      <div className={s.inner}>
        <div className={s.item}>
          <div className={s.img_wrapper}>
            <img src='/images/trade1.jpeg' className={s.img} />
          </div>
          <div className={s.text}>
            Trade facilitation and business development
          </div>
        </div>
        <div className={s.item}>
          <div className={s.img_wrapper}>
            <img src='/images/8.jpeg' className={s.img}></img>
          </div>
          <div className={s.text}>
            Logistics, transit corridors and cross-border management
          </div>
        </div>
        <div className={s.item}>
          <div className={s.img_wrapper}>
            <img src='/images/protection2.jpeg' className={s.img}></img>
          </div>
          <div className={s.text}>Customs clearance and legal protection</div>
        </div>
        <div className={s.item}>
          <div className={s.img_wrapper}>
            <img src='/images/digital2.jpeg' className={s.img}></img>
          </div>

          <div className={s.text}>Digital and electronic trade systems</div>
        </div>
        <div className={s.item}>
          <div className={s.img_wrapper}>
            <img src='/images/sharing5.webp' className={s.img}></img>
          </div>
          <div className={s.text}>
            Knowledge sharing and capacity building activities
          </div>
        </div>
        <div className={s.item}>
          <div className={s.img_wrapper}>
            <img src='/images/expert.png' className={s.img}></img>
          </div>
          <div className={s.text}>
            Expert services in the region and international organizations
          </div>
        </div>
        <div className={s.item}>
          <div className={s.img_wrapper}>
            <img src='/images/research.jpeg' className={s.img}></img>
          </div>
          <div className={s.text}>Research and Analysis</div>
        </div>
      </div>
    </div>
  );
};

export default ActivityDirections;
