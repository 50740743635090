import React, { useState } from "react";
import s from "./MobileHeader.module.scss";
import { Modal } from "antd";
import { Link, useLocation } from "react-router-dom";
import {
  HomeOutlined,
  ApartmentOutlined,
  TeamOutlined,
  AuditOutlined,
  LinkOutlined,
  BookOutlined,
} from "@ant-design/icons";

const MobileHeader = () => {
  const [isModalOpen, setIsModalOpen] = useState();
  const location = useLocation();

  return (
    <div className={s.mobileHeader}>
      {location.pathname !== "/" ? (
        <img src='/images/header.png' className={s.header_img} />
      ) : null}
      <div className={s.header_wrapper}>
        <Link
          to='/'
          className={s.logo_wrapper}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <div className={s.logo_img}>
            <img src='/images/logo.png' className={s.logo} />
          </div>
        </Link>
        <div className={s.mobile_menu} onClick={() => setIsModalOpen(true)}>
          <div></div>
          <div></div>
        </div>
      </div>

      <Modal
        width={400}
        height={280}
        onCancel={() => setIsModalOpen(false)}
        style={{ borderRadius: "10px", width: "90%", margin: "0 auto" }}
        cancelButtonProps={{ style: { display: "none", color: "whitesmoke" } }}
        okButtonProps={{ style: { display: "none" } }}
        className={s.mobile_menu_modal}
        open={isModalOpen}
      >
        <form className='mobile_header' onSubmit={(e) => e.preventDefault()}>
          <Link
            to='/'
            className={s.mobile_menu_item}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className='slash'>
              <HomeOutlined className={s.icon} />
            </div>
            Home
          </Link>
          <Link
            to='/organigram'
            className={s.mobile_menu_item}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className='slash'>
              <ApartmentOutlined className={s.icon} />
            </div>
            Organigram
          </Link>
          <Link
            to='/career'
            className={s.mobile_menu_item}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className='slash'>
              <TeamOutlined className={s.icon} />
            </div>
            Careers
          </Link>
          <Link
            to='/committee'
            className={s.mobile_menu_item}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className='slash'>
              <BookOutlined className={s.icon} />
            </div>
            Advisory committee
          </Link>
          <Link
            to='/tarrifs'
            className={s.mobile_menu_item}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className='slash'>
              <AuditOutlined className={s.icon} />
            </div>
            Customs Reprecentation
          </Link>
          <Link
            to='/events'
            className={s.mobile_menu_item}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className='slash'>
              <AuditOutlined className={s.icon} />
            </div>
            Events
          </Link>
          <Link
            to='/news'
            className={s.mobile_menu_item}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className='slash'>
              <LinkOutlined className={s.icon} />
            </div>
            News
          </Link>
        </form>
      </Modal>
    </div>
  );
};

export default MobileHeader;
