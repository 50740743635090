import React from "react";
import Header from "../../components/Header/Header";
import s from "./Tarrifs.module.scss";
import { Table } from "antd";
import Footer from "../../components/Footer/Footer";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import useWindowDimensions from "../../components/WindowDimentions/WindowDimentions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { useSelector } from "react-redux";
import { DownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
var FileSaver = require("file-saver");

const columns = [
  {
    title: "№",
    dataIndex: "number",
  },
  {
    title: `Country`,
    dataIndex: "country",
    with: 50,
  },
  {
    title: `Tariff`,
    dataIndex: "tariff",
  },
];

const Tarrifs = () => {
  const { width } = useWindowDimensions();
  const tariffs = useSelector((state) => state.content.tariffs);

  const openFileInNewWindow = (e, file) => {
    e.preventDefault();
    const fileUrl = file;
    window.open(fileUrl, "_blank");
  };

  const downloadFile = (e, file, country) => {
    FileSaver.saveAs(file, `${country}_tariff.pdf`);
  };

  return (
    <div>
      <div className={s.header}>
        {width > 1000 ? (
          <div className={s.header_wrapper}>
            <Header />
          </div>
        ) : (
          <MobileHeader />
        )}
      </div>
      <div className={s.tarrifs}>
        <h2 className={s.h2}>
          TARIFF SCHEDULE <br /> for services provided by MCTAC LLC in customs
          clearance procedures
        </h2>
      </div>

      <div className={s.table_wrapper}>
        <Table
          bordered
          pagination={false}
          columns={columns}
          dataSource={
            Array.isArray(tariffs) &&
            tariffs.map((it, index) => ({
              number: <div className={s.td}>{index + 1}</div>,
              country: (
                <div className={s.table_country}>
                  <img src={it?.icon} className={s.countryIcon} />
                  <div className={s.td}>{it?.country}</div>{" "}
                </div>
              ),
              tariff: (
                <div className={s.tariff_wrapper}>
                  <button
                    className={s.open}
                    onClick={(e) => openFileInNewWindow(e, it.file)}
                  >
                    <FilePdfOutlined className={s.icon} /> Tariffs
                  </button>

                  <button
                    className={s.download}
                    onClick={(e) => downloadFile(e, it.file, it.country)}
                  >
                    <DownloadOutlined />
                  </button>
                </div>
              ),
            }))
          }
        />
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Tarrifs;
