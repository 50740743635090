import React from "react";
import Header from "../../components/Header/Header";
import s from "./Agenda.module.scss";
import Footer from "../../components/Footer/Footer";
import { Table } from "antd";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getEventAgenda } from "../../redux/ApiSlice";
import useWindowDimentions from "../../components/WindowDimentions/WindowDimentions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";

const columns = [
  {
    title: "Date",
    dataIndex: "date",
    width: 130,
  },
  {
    title: "Time",
    dataIndex: "time",
    width: 140,
  },

  {
    title: `Activity`,
    dataIndex: "activity",
  },
];

const columns2 = [
  {
    title: "Date",
    dataIndex: "date",

    with: 200,
  },

  {
    title: `Activity`,
    dataIndex: "activity",
    with: 400,
  },
];

const Agenda = () => {
  const agenda = useSelector((state) => state.content.agenda);
  const data2 = [];
  const mobileDataSource = [];
  const { width } = useWindowDimentions();

  Array.isArray(agenda) &&
    agenda.map((it) =>
      it.agenda_items.map((el, index) =>
        data2.push({
          key: index,
          date: index === 0 ? it?.date : null,
          time: `${el?.start_time?.slice(0, -3)} - ${el?.end_time.slice(
            0,
            -3
          )}`,
          activity: el.activity,
        })
      )
    );

  Array.isArray(agenda) &&
    agenda.map((it) =>
      it.agenda_items.map((el, index) =>
        mobileDataSource.push({
          key: index,
          date: (
            <div>
              <div className={s.td} style={{ minWidth: "70px" }}>
                {index === 0 ? it?.date : null}
              </div>
              <div className={s.td}>
                {el?.start_time?.slice(0, -3)} - {el?.end_time.slice(0, -3)}
              </div>
            </div>
          ),
          activity: <div className={s.td}>{el.activity}</div>,
        })
      )
    );

  return (
    <div className={s.agenda}>
      {width > 1200 ? (
        <div className={s.header_wrapper}>
          <Header />
        </div>
      ) : (
        <MobileHeader />
      )}
      <Link to='/events/1' className={s.link}>
        {" "}
        <ArrowLeftOutlined className={s.arrowIcon} /> Back to Event
      </Link>
      <div className={s.content}>
        <h2 className={s.h2}>AGENDA</h2>
      </div>
      <div className={s.table}>
        {width > 650 ? (
          <Table
            bordered
            pagination={false}
            columns={columns}
            dataSource={data2}
          />
        ) : (
          <Table
            bordered
            pagination={false}
            columns={columns2}
            dataSource={mobileDataSource}
          />
        )}
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Agenda;
