import React from "react";
import s from "./TeamMember.module.scss";
import { DownloadOutlined } from "@ant-design/icons";

const TeamMember = ({ member }) => {
  const openFileInNewWindow = (e, file) => {
    e.preventDefault();
    const fileUrl = file;
    window.open(fileUrl, "_blank");
  };

  return (
    <div className={s.member}>
      <div className={s.img_wrapper}>
        <img src={member.img} className={s.img} />
      </div>
      <div className={s.member_desc}>
        <div className={s.name}>{member.name}</div>
        <div className={s.country}>
          <div className={s.country_name}>
            <img src={member.flag} className={s.flag} /> {member.country}
          </div>
          {member.cv ? (
            <div
              className={s.cv}
              onClick={(e) => openFileInNewWindow(e, member.cv)}
            >
              <DownloadOutlined className={s.icon} /> CV
            </div>
          ) : null}
        </div>
        <div className={s.position}>{member.position}</div>
      </div>
    </div>
  );
};

export default TeamMember;
