import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import s from "./Program.module.scss";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Table } from "antd";
import { useSelector } from "react-redux";
import useWindowDimensions from "../../components/WindowDimentions/WindowDimentions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";

const columns = [
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "Time",
    dataIndex: "time",
    width: 130,
  },
  {
    title: "",
    dataIndex: "description",
  },
];

const columns2 = [
  {
    title: "Date",
    dataIndex: "date",
    with: 150,
  },
  {
    title: "",
    dataIndex: "description",
  },
];

const Program = () => {
  const program = useSelector((state) => state.content.program);
  const { width } = useWindowDimensions();

  return (
    <div className={s.program}>
      {width > 1200 ? (
        <div className={s.header_wrapper}>
          <Header />
        </div>
      ) : (
        <MobileHeader />
      )}
      <Link to={`/events/${program.id}`} className={s.link}>
        {" "}
        <ArrowLeftOutlined className={s.arrowIcon} /> Back to Event
      </Link>

      <div className={s.content}>
        <h2 className={s.h2}>PROGRAM OF THE CONFERENCE</h2>
        <div className={s.table}>
          {width > 650 ? (
            <Table
              bordered
              pagination={false}
              columns={columns}
              dataSource={program?.map((it, index) => ({
                number: index + 1,
                date: `${it?.date === null ? "" : it?.date}  ${
                  it.title === null ? "" : it.title
                }`,
                description: it?.description,
                time: `${it?.start_time?.slice(0, -3)} - ${it?.end_time.slice(
                  0,
                  -3
                )}`,
              }))}
            />
          ) : (
            <Table
              bordered
              pagination={false}
              columns={columns2}
              dataSource={program?.map((it) => ({
                date: (
                  <div>
                    <div className={s.td}>
                      {it?.date === null ? "" : it?.date}
                    </div>
                    <div className={s.td}>
                      {it.title === null ? "" : it.title}
                    </div>
                    <div className={s.td}>
                      {it?.start_time?.slice(0, -3)} -{" "}
                      {it?.end_time.slice(0, -3)}
                    </div>
                  </div>
                ),
                description: <div className={s.td}>{it?.description}</div>,
              }))}
            />
          )}
        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Program;
